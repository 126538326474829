.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.header-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
}

.register {
  background-color: #61dafb;
  border: none;
  color: #282c34;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.register:hover {
  background-color: #21a1f1;
}

h1 {
  font-size: 4rem;
  color: #a3d4f7;
}

h2 {
  margin-top: 2rem;
  font-size: 2rem;
}

ul {
  text-align: left;
  list-style-type: none;
  padding: 0;
}

li {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.RegisterPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #2c2c2c; /* Dark background color */
  padding: 20px;
  box-sizing: border-box;
}

input[type="email"] {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.register-button {
  color: #282c34;
  background-color: #a3d4f7;
  cursor: pointer;
  font-weight: bold;
}

.register-button:hover {
  background-color: #21a1f1;
}

.register-button:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
}

a {
  color: #61dafb;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Dashboard Styles */
.Dashboard {
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the dashboard */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
}

.account-details,
.api-key,
.billing-and-payments,
.dashboard-section {
  background: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-section h2 {
  font-size: 20px;
  color: #61dafb; /* Match the color of other section titles */
  margin-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
}

.logout-button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: darkred;
}

p {
  margin: 10px 0;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.api-key-value {
  font-weight: bold;
  color: #e74c3c; /* Red color for unavailable API key */
}

.payment-methods {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #007bff;
}

.payment-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.add-payment-method {
  cursor: pointer;
  color: #007bff;
}

.api-key-display {
  display: flex;
  align-items: center;
}

.api-key-display input {
  flex-grow: 1;
  margin-right: 10px;
}

.api-key-display button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.api-key-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.api-key-input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.api-key-input.hidden {
  -webkit-text-security: disc;
}

.api-key-input.visible {
  -webkit-text-security: none;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.service-details ul {
    list-style-type: none;
    padding-left: 0;
}

.service-details li {
    margin-bottom: 10px;
}

.intake-field {
  margin-bottom: 20px;
}

.intake-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.intake-field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
}

.char-count {
  text-align: right;
  color: #6c757d;
  font-size: 0.9em;
  margin-top: 5px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button.enabled {
  background-color: #007bff;
  color: white;
}

.submit-button.disabled {
  background-color: #6c757d;
  color: #f8f9fa;
  cursor: not-allowed;
}

.success-message {
  color: #28a745;
  margin-top: 10px;
}

/* Documentation Styles */
.documentation-content {
  text-align: left;
  margin: 20px 0;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.documentation-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.documentation-content p {
  margin: 10px 0;
  line-height: 1.6;
}

.documentation-content ul {
  padding-left: 20px;
  margin: 10px 0;
}

.documentation-content li {
  margin-bottom: 5px;
}

.documentation-content code {
  background-color: #e8e8e8;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

.documentation-content pre {
  background-color: #e8e8e8;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}
